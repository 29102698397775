<template>
  <div class="flex justify-content-center align-items-center" id="wrapper" :style="backgroundImg">
    <div class="flex col-0 lg:col-3" id="desktop-block">
      <img src="DS-logo.webp" id="dslogo" />
    </div>
    <div class="container col-12 lg:col-9 m-0 pl-4 pr-4" id="content-block" :style="contentBlockStyle">
      <div id="content-top"></div>
      <div class="flex justify-content-center mt-4 mb-4">
        <img v-if="isMobile" src="DS-logo.webp" id="dslogo-mobile" />
      </div>
      <div class="flex justify-content-start" id="controls" style="overflow-x: auto;">
        <router-link :to="'/'" class="router-link-styles">
          <button :style="contentButtonStyle('home')">HOME</button>
        </router-link>
        <router-link :to="'/player'" class="router-link-styles">
          <button :style="contentButtonStyle('player')">PLAYER</button>
        </router-link>
        <router-link :to="'/mobs'" class="router-link-styles">
          <button :style="contentButtonStyle('mobs')">MOBS</button>
        </router-link>
        <router-link :to="'/unlocks'" class="router-link-styles">
          <button :style="contentButtonStyle('unlocks')">UNLOCKS</button>
        </router-link>
        <router-link :to="'/audio'" class="router-link-styles">
          <button :style="contentButtonStyle('audio')">AUDIO</button>
        </router-link>
        <router-link :to="'/misc'" class="router-link-styles">
          <button :style="contentButtonStyle('misc')">MISC</button>
        </router-link>
      </div>

      <!-- <hr style="border: 1px solid black; margin: 0px;"> -->

      <div class="flex justify-content-center" id="dynamic">
        <div class="mt-5" style="width: 100%">
          <router-view v-slot="{ Component, route }">
            <transition name="slide-fade" mode="out-in">
              <component :is="Component" :key="route.path" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <img :src="slayerSprite" id="slayer_sprite_gif" @mousedown="slayerInteract" @touchstart="slayerInteract"
      :style="slayerOffset"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageFileName: "background.png",
      slayerSprite: "res/idle.gif",
      haveInteracted: false,
      spriteBottomOffset: 100,
      spriteWidth: 100,
      windowWidth: window.innerWidth,
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    slayerInteract() {
      if (!this.haveInteracted) {
        const newSprite = new Image();
        newSprite.src = "res/fall.gif";

        newSprite.onload = () => {
            this.slayerSprite = newSprite.src;
            this.spriteWidth = 185;
        };

        // this.slayerSprite = "res/fall.gif";
        
        // setTimeout(() => {
        //   this.spriteWidth += 85;
        // }, 20)

        const viewportHeight = window.innerHeight;
        const moveAmount = viewportHeight * 0.02;

        let animationPeriod = setInterval(() => {
          this.spriteBottomOffset -= moveAmount;
        }, 100);

        setTimeout(() => {
          clearInterval(animationPeriod);
        }, 400);

        this.haveInteracted = true;
      }
    },

    contentButtonStyle(content) {
      if (this.$route.name === content) {
        return "background-color: rgba(255, 249, 161, 0.5);";
      } else {
        return "";
      }
    },

    isCurrentPage(content) {
      // Not in use
      return (this.$route.name === content);
    }
  },

  watch: {
    "$route": {
      handler() {
        let element = document.getElementById("controls");
        element.scrollIntoView();
      },
    },
  },

  computed: {
    isMobile() {
      return this.windowWidth < 992;
    },

    slayerOffset() {
      return "bottom: " + this.spriteBottomOffset + "px; width: " + this.spriteWidth + "px;";
    },

    contentBlockStyle() {
      return this.isMobile ? 'height: 100vh;' : 'height: 80vh;'
    },

    backgroundImg() {
      return { backgroundImage: `url(${this.imageFileName})` };
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.7s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.7s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 2s ease;
}

.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#dslogo {
  position: relative;
  width: 80%;
  left: 10%;
  bottom: 100px;
  object-fit: scale-down;
}

#dslogo-mobile {
  position: relative;
  width: 70%;
}

#wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
}

#desktop-block {
  margin-right: 0px;
  height: 100%;
}

#content-block {
  overflow: auto;
  border-left: 2px solid black;
}

#dynamic {
  overflow: hidden;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-image: url('../../public/res/footer.png');
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: calc(-100%);
  z-index: 1;
  pointer-events: none;
}

#slayer_sprite_gif {
  position: relative;
  left: 5%;
  transform: scaleX(-1);
  z-index: 2;
  pointer-events: painted;
}

.router-link-styles {
  width: 100%;
  height: 3em;
  padding: 0px 2px;
}

/* .partition {
  border-right: 3px solid white;
} */

button {
  border: none;
  height: 100%;
  width: 100%;
  font-size: 1.6em;
  font-family: serif;
  background:
    linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to right, black 2px, transparent 2px) 0 100%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0,
    linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, black 2px, transparent 2px) 100% 0,
    linear-gradient(to top, black 2px, transparent 2px) 0 100%,
    linear-gradient(to top, black 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 7px 7px;
  background-color: rgba(255, 255, 255, 0.5);
}

button:hover {
  font-size: 1.8em;
}

button[disabled],
button:disabled {
  color: black;
}

#controls::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}
</style>